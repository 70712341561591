import React, { useContext, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { EmailContext } from '../context/EmailContext';
import { FaUpload } from 'react-icons/fa';
import Swal from 'sweetalert2'


export default function AddBulkEmails() {
  // const [emails, setEmails] = useState([]);
  // const [error, setError] = useState(null);
  // const [fileName, setFileName] = useState('');
  const { addBulkEmail } = useContext(EmailContext);
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    // setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const emailList = jsonData.flat().filter((email) => validateEmail(email));

      if (emailList.length > 0) {
        addBulkEmail(emailList);
      } else {
        Swal.fire({
          icon:'error',
          title:'No Emails',
          text:`No valid email addresses found in : ${file.name}`,
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  // Trigger file input click when upload button is clicked
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'fit-content' }}>
      <div style={{ display: 'flex', maxWidth: '400px', width: '100%' }}>
        <button
          onClick={handleUploadClick}
          className="btn btn-primary btn-sm"
          title='Upload excell file'
        >
          <FaUpload /> <small>upload file</small>
        </button>
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: 'none' }} // Hidden input field
        />
      </div>
    </div>
  );
}
