import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from '../../utils/ImageViewer';
import { TemplateTwoContext } from './TemplateTwoContext';
import logo from '../../images/logo.png';

const Preview = () => {
  const { instance } = useContext(TemplateTwoContext);

const FACEBOOK_LINK ='https://www.facebook.com/kenyaincentivessafaris'
const INSTAGRAM_LINK='https://www.instagram.com/kenyaincentives'
const YOUTUBE_LINK='https://www.youtube.com/@KenyaIncentivesSafaris'
const LINKEDIN_LINK='https://www.linkedin.com/in/benjamin-kotut-26661bb9'
const X_LINK= 'https://x.com/kenyaincentives'


// # https://package2-besh-tours-managing-app.vercel.app/inquiries/add
const WEBSITE_LINK='https://kenyaincentivessafaris.com'
// const BOOK_NOW ='https://kenyaincentivessafaris.com' 


  const images = [
    instance?.head_image,
    instance?.sub1_image,
    instance?.sub2_image,
    instance?.sub3_image,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1);
    setIsOpen(true);
  };

  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            background-color: #ffffff;
            margin: auto;
            padding: 0;
            line-height: 1.6;
            color: #444;
          }
          .container {
            width: 100%;
            max-width: 600px;
            margin: auto;
            padding: 0;
            background-color: rgba(152, 255, 152, 0.42);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            overflow: hidden;
          }
          #header-row {
            padding: 0;
            margin: 0;
          }
          .header {
            width: 100%;
            height: auto;
            background-image: url('${instance?.head_image}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
          }
          .header-content {
            position: relative;
            z-index: 2;
            text-align: center;
            padding: 30px 20px;
            color: white;
          }
          .header-content img {
            height: 100px;
            animation: imageFadeIn 2s;
          }
          .header-content h1 {
            margin: auto;
            font-size: 2em;
            font-weight: bold;
            animation: fadeIn 2s;
            color: wheat;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          }
          .header-content p {
            font-size: 1.3em;
            color: whitesmoke;
            animation: fadeIn 2s;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          }
          .main-content {
            padding: 20px 30px;
          }
          .main-content h2 {
            color: #333;
            font-size: 2em;
            margin-bottom: 10px;
          }
          .main-content p {
            color: #666;
            margin-bottom: 20px;
          }
          .section {
            text-align: center;
            margin-bottom: 20px;
          }
          .section-content img {
            width: 100%;
            max-width: 100%;
            height: auto;
            border-radius: 2px;
            margin-bottom: 20px;
            animation: imageFadeIn 2s;
            transition: transform 0.3s ease;
          }
          .section-content img:hover {
            transform: scale(1.05);
          }
          .button {
            display: inline-block;
            padding: 10px 40px;
            margin: 10px 30px;
            background-color: #32CD32;
            color: #FFD700;
            text-decoration: none;
            border: 1px solid #808000;
            border-radius: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .footer {
            background-color: #333;
            color: #fff;
            text-align: center;
            padding: 20px;
            border-top: 2px solid gold;
            font-size: 0.9em;
          }
          .social-icons {
            margin: 10px 0;
          }
          .social-icons a {
            margin: 0 5px;
            display: inline-block;
          }
          .social-icons img {
            width: 30px;
            height: 30px;
            border: none;
            transition: transform 0.3s ease;
          }
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes imageFadeIn {
            from { opacity: 0; transform: scale(1.2); }
            to { opacity: 1; transform: scale(1); }
          }
          @media only screen and (max-width: 500px) {
            .container {
              width: 100% !important;
            }
          }
        `}
      </style>

      <div className="container">
        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
          <tr id="header-row">
            <td>
              <div className="header">
                <div className="header-content">
                  <img src={logo} alt="logo" />
                  <h1>{instance?.head_title}</h1>
                  <p>{instance?.head_text}</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="main-content">
              <h2>{instance?.main_title}</h2>
              <p><span>Hello,</span><br />{instance?.main_text}</p>
              <div className="section">
                <div className="section-content">
                  <h2 style={{ color: '#28a745' }}>{instance?.sub1_title}</h2>
                  <ImageViewer 
                    images={images}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    scale={scale}
                    setScale={setScale}
                  />
                  <img src={instance?.sub1_image} onClick={() => openModal(1)} alt="Tour" />
                  <p>{instance?.sub1_text}</p>
                </div>
              </div>
              <div className="section">
                <div className="section-content">
                  <h2 style={{ color: '#28a745' }}>{instance?.sub2_title}</h2>
                  <ImageViewer 
                    images={images}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    scale={scale}
                    setScale={setScale}
                  />
                  <img src={instance?.sub2_image} onClick={() => openModal(2)} alt="Tour" />
                  <p>{instance?.sub2_text}</p>
                </div>
              </div>
              <div className="section">
                <div className="section-content">
                  <h2 style={{ color: '#28a745' }}>{instance?.sub3_title}</h2>
                  <ImageViewer 
                    images={images}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    scale={scale}
                    setScale={setScale}
                  />
                  <img src={instance?.sub3_image} onClick={() => openModal(3)} alt="Tour" />
                  <p>{instance?.sub3_text}</p>
                </div>
              </div>
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <a href={instance?.view_more_link} className="button">View More</a>
                <a href={WEBSITE_LINK} className="button">Go to website</a>
              </div>
            </td>
          </tr>
          <tr>
            <td className="footer">
              <div className="social-icons">
                <a href={FACEBOOK_LINK}>
                  <img src="https://img.icons8.com/ios-filled/50/1877F2/facebook-new.png" alt="Facebook" />
                </a>
                <a href={X_LINK}>
                  <img src="https://ik.imagekit.io/4rfgtafuc0/social%20media/x(white).png?updatedAt=1725557546866" alt="X" />
                </a>
                <a href={INSTAGRAM_LINK}>
                  <img src="https://www.pngmart.com/files/21/Instagram-PNG-Isolated-File.png" alt="Instagram" />
                </a>
                <a href={LINKEDIN_LINK}>
                  <img src="https://img.icons8.com/ios-filled/50/0072b1/linkedin.png" alt="LinkedIn" />
                </a>
                <a href={YOUTUBE_LINK}>
                  <img src="https://img.icons8.com/ios-filled/50/ff0000/youtube-play.png" alt="YouTube" />
                </a>
              </div>
              <p>© 2024 Kenya Incentives Tours & Safaris. All rights reserved.</p>
              <p>
                <a href="" style={{ color: '#28a745', textDecoration: 'none' }}>Unsubscribe</a> | 
                <Link to="/pages/privacy-policy" style={{ color: '#28a745', textDecoration: 'none' }}> Privacy Policy</Link>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Preview;
